<template>
  <OverlayContainer tag-name="aside" :class="$style['video-gallery-overlay']">
    <div :class="$style['video-gallery-overlay__logo-wrapper']">
      <img
        src="../../../assets/img/main-menu/logo.png"
        alt=""
        :class="$style['video-gallery-overlay__logo']"
      />
    </div>
    <ContentContainer :class="$style['video-gallery-overlay__content']">
      <BaseGallery :items="items" :uniform-thumbnail="videoThumbnail">
        <template v-slot:selected="{ item }">
          <div :class="$style['video-gallery-overlay__selected-video-wrapper']">
            <MediaVideo :strapi="true" :src="item.url" :mime="item.mime" />
          </div>
        </template>
      </BaseGallery>
    </ContentContainer>
  </OverlayContainer>
</template>

<script>
import OverlayContainer from '@/components/containers/OverlayContainer';
import BaseGallery from '@/components/media/gallery/BaseGallery';
import galleryBaseMixin from '@/components/media/gallery/galleryBaseMixin';
import MediaVideo from '@/components/media/MediaVideo';
import videoThumbnail from '@/assets/img/media/media__video-thumbnail.svg';
import 'plyr/dist/plyr.css';
export default {
  name: 'VideoGalleryOverlay',
  components: {
    OverlayContainer,
    BaseGallery,
    MediaVideo
  },
  mixins: [galleryBaseMixin],
  data() {
    return {
      videoThumbnail
    };
  }
};
</script>

<style module>
.video-gallery-overlay {
  @apply bg-black bg-opacity-90;
}

.video-gallery-overlay__logo-wrapper {
  @apply flex justify-center items-center py-4 h-1/8;
}

.video-gallery-overlay__logo {
  @apply w-1/2;
}

.video-gallery-overlay__content {
  @apply w-full h-full py-0 h-7/8;
}

.video-gallery-overlay__selected-video-wrapper {
  @apply w-full h-full flex items-center justify-center;
}
</style>
