<template>
  <video preload="metadata" ref="video" playsinline controls>
    <source :src="computedSrc" :type="mime" />
  </video>
</template>

<script>
import Plyr from 'plyr';
import mediaBaseMixin from '@/components/media/mediaBaseMixin';
export default {
  name: 'MediaVideo',
  mixins: [mediaBaseMixin],
  props: {
    mime: {
      type: String,
      required: true,
      validator: prop => {
        const splitProp = prop.split('/');
        return splitProp.length === 2 && splitProp[0] === 'video';
      }
    }
  },
  data() {
    return {
      player: null
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.player = new Plyr(this.$refs.video);
      // this is to display first frame as poster
      this.player.on('loadeddata', () => {
        this.player.currentTime = 0.5;
      });
    });
  },
  beforeDestroy() {
    this.player.destroy();
  },
  watch: {
    computedSrc: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.$refs.video.load();
        });
      }
    }
  }
};
</script>

<style>
.plyr {
  @apply max-w-full max-h-full;
}

.plyr--video .plyr__control.plyr__control--overlaid,
.plyr--video .plyr__control.plyr__control--overlaid:hover {
  @apply bg-primary;
}

.plyr--video .plyr__control.plyr__control--overlaid:hover {
  @apply opacity-70;
}

.plyr__controls .plyr__controls__item .plyr__progress > input,
.plyr__controls .plyr__controls__item.plyr__volume > input {
  @apply text-primary;
}

.plyr__controls button.plyr__controls__item:hover,
.plyr__controls .plyr__controls__item button[aria-expanded='true'],
.plyr__controls .plyr__controls__item .plyr__control:hover,
.plyr__controls
  .plyr__controls__item
  .plyr__menu__container
  button[aria-checked='true']:before {
  @apply bg-primary;
}
</style>
